import React, { useEffect, useState } from 'react';
import './LoadingAnimation.css';
interface IProps {
    trigger: boolean;
}
export const LoadingAnimation = ({ trigger }: IProps) => {
    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        if (trigger) {
            setAnimate(true);
        } else {
            setAnimate(false);
        }
    }, [trigger]);

    return (
        <div className={`loading-animation ${animate ? 'animate' : ''}`}>
            <svg className="spinner" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="4" />
            </svg>
        </div>
    );
};

