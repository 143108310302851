import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import { AppProvider, useAppContext } from '@utils';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import { appTheme } from '@styles';
import 'react-toastify/dist/ReactToastify.min.css';
import { ToastContainer } from 'react-toastify';

const renderApp = async () => {
  const getUserIP = async () => {
    try {
      const response = await fetch("http://ip-api.com/json")
      if (!response.ok)
        throw new Error(`HTTP error! status: ${response.status}`);
      const responseJson = await response.json();
      const countryCode = responseJson.countryCode;
      localStorage.setItem("countryCode", countryCode)
      return response;
    }
    catch (e) {
      console.log(e);
    }
  };
  // useLayoutEffect(() => {
  //   getUserIP()
  // }, [])
  // const initialData = await getUserIP();

  const theme = createTheme(appTheme);

  const Index = () => (
    <AppProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ToastContainer
          autoClose={5000}
          toastStyle={{
            background: theme.palette.background.paper,
            color: theme.palette.text.primary,
          }}
        />
        <App />
      </ThemeProvider>
    </AppProvider>
  );

  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
  root.render(
    // <React.StrictMode>
    <Index />
    // </React.StrictMode>
  );
};

renderApp();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
