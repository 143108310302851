import React, { useContext, useEffect, useState } from "react";
import { useAppContext } from "@utils";
import { Avatar, Box, Grid, IconButton, Typography } from "@mui/material";
import f_bg1_bg from '@images/f_bg1_bg.png';
import f_bg1 from '@images/f_bg1_tp.png';
import f_bg1_mob from '@images/f_bg1_chunks_mob.png';
import f_bg2 from '@images/f_bg2.png';
import CS_RaniJuice from '@images/CS_RaniJuice.png';
import UAE_CS_RaniJuice from '@images/UAE_CS_RaniJuice.png'
import fade_blue from '@images/fade-blue.png';
import { FormContent } from "@components";
import { CanAnimation } from "@animations";
import Chuncks_pack_l from '@images/Chuncks_pack_l.png';
import Chuncks_pack_r from '@images/Chuncks_pack_r.png';
import { observer } from "mobx-react";
import { ToastContainer } from "react-toastify";

export const Home = observer(() => {
    const {
        appStore: {
            getStore: appStore, country
        }
    } = useAppContext();
    const [imgLoaded, setImgLoaded] = useState(false)
    const isUae = country === "UAE" || false;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <Grid container className="min-h-screen ">
            <Grid item xs={12} position={'relative'} minHeight={{ xs: '0vh', sm: '0' }} className="bg-cover " sx={{ backgroundImage: { xs: `url(${f_bg1_bg})`, sm: `url(${f_bg1_bg})` }, backgroundPosition: 'bottom', display: 'flex', flexDirection: 'column', justifyContent: { xs: 'center', sm: 'flex-end' }, alignItems: 'center' }}>
                <Avatar onLoad={() => setImgLoaded(true)} variant="square" src={isUae ? UAE_CS_RaniJuice : f_bg1} sx={{ height: 'auto', width: { xs: '90%', sm: '60%' }, zIndex: 2, top: { xs: '15%', sm: "0" } }} />
                <Avatar variant="square" src={fade_blue} sx={{ height: 'auto', width: '100%', zIndex: 2, position: 'absolute', bottom: '-15%' }} />
                {imgLoaded &&
                    <>
                        <Avatar className="breath-left-once" variant="square" src={Chuncks_pack_l} sx={{ height: 'auto', width: { xs: '30%', sm: '40%' }, rotate: { xs: '55deg', sm: '-10deg' }, position: 'absolute', top: { xs: '18%', sm: "-10%" }, left: { xs: '35%', sm: '20%' }, zIndex: 1 }} />
                        <Avatar className="breath-right-once" variant="square" src={Chuncks_pack_r} sx={{ height: 'auto', width: { xs: '30%', sm: '40%' }, rotate: { xs: '-65deg', sm: '-10deg' }, position: 'absolute', top: { xs: '22%', sm: "10%" }, right: { xs: '40%', sm: '20%' }, zIndex: 1 }} />
                    </>
                }
            </Grid>
            <Grid item xs={12} minHeight={''} position={'relative'} display={'flex'} alignItems={'center'} justifyContent={'center'} marginTop={'-5%'} marginBottom={'2%'}>
                <Box marginTop={'15%'} position={'absolute'} top={0} left={0} width={'100%'} height={'100%'} className="bg-contain opacity-50" sx={{ backgroundImage: `url(${f_bg2})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', zIndex: 1 }} />
                <FormContent />
            </Grid>
            <CanAnimation />
        </Grid>
    )
})