import React, { useContext, useEffect } from "react";
import { paths, useAppContext } from "@utils";
import { Avatar, Box, Button, Grid, Typography } from "@mui/material";
import CS_bg1 from '@images/CS_bg1&2.png';
import CS_WinRani from '@images/CS_WinRani.png';
import CS_WinRani_arabic from '@images/CS_WinRani_arabic.png';

import CS_RaniJuice from '@images/CS_RaniJuice.png';

import Chuncks_pack_l from '@images/Chuncks_pack_l.png';
import Chuncks_pack_r from '@images/Chuncks_pack_r.png';
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";


export const ErrorPage = observer(() => {
    const {
        appStore: {
            getStore: appStore, language, client
        }
    } = useAppContext();
    const navigate = useNavigate();

    const isArabic = language === "Arabic";
    const handleTryAgain = () => {
        navigate(paths.home);
    }
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <Grid container className="min-h-screen relative content-center">
            <Grid item xs={12} minHeight={{ xs: '50vh', sm: '100vh' }} className="bg-cover content-center" sx={{ backgroundImage: `url(${CS_bg1})` }}>
                <Grid container>
                    <Grid item xs={12} sm={12} md={6} alignContent={'center'}>
                        <Avatar variant="square" src={CS_RaniJuice} sx={{ height: 'auto', width: '100%' }} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} className="content-center">
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 4, padding: "2%", textAlign: 'center' }}>
                            <Typography width={'100%'} component={"span"} fontSize={{ xs: '2rem', sm: '5rem' }} color={'#F06661'} fontWeight={700}>
                                {"Apologies!"}
                            </Typography>
                            <Typography width={'100%'} component={"span"} fontSize={{ xs: '2rem', sm: '4rem' }} color={'#F06661'} fontWeight={700}>
                                {"!نعتذر"}
                            </Typography>
                            <Typography width={'100%'} component={"span"} fontSize={{ xs: '2rem', sm: '3rem' }} color={'#FDE067'} fontWeight={700}>
                                {`It looks like you are not in the country that you've selected. Please select the correct country and try again`}
                            </Typography>
                            <Typography width={'100%'} component={"span"} fontSize={{ xs: '2rem', sm: '3rem' }} color={'#FDE067'} fontWeight={700}>
                                {".يبدو أنك لست في البلد الذي حددته. الرجاء تحديد البلد الصحيح والمحاولة مرة أخرى"}
                            </Typography>
                            <Button variant="contained" onClick={handleTryAgain}
                                sx={{ padding: '1% 2%', borderRadius: '10px', background: "linear-gradient(180deg, #FD8484 0%,  #F06661 77%, #DF3F34 100%)", fontSize: { xs: '1rem', sm: '2rem' } }}
                            >
                                {'Try Again حاول ثانية'}
                            </Button>
                            <Typography width={'100%'} component={"span"} fontSize={{ xs: '0.8rem', sm: '1.5rem' }} color={'#FDE067'} fontWeight={500}>
                                {`If you continue to have issue with country selection, please email us at: `} <br />
                                {":إذا كنت لا تزال تواجه مشكلة في اختيار البلد، يرجى مراسلتنا عبر البريد الإلكتروني على"} <br />
                                <Typography width={'100%'} component={"span"} fontSize={{ xs: '0.8rem', sm: '1.5rem' }} color={'#FDE067'} fontWeight={900}>
                                    contact@winwithrani.com
                                </Typography>
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
})


// <Grid container className="min-h-screen relative content-center">
//             <Grid item xs={12} minHeight={{ xs: '50vh', sm: '100vh' }} className="bg-cover content-center" sx={{ backgroundImage: `url(${CS_bg1})` }}>
//                 <Grid container>
//                     <Grid item xs={12} sm={6}>
//                         <Avatar variant="square" src={CS_RaniJuice} sx={{ height: 'auto', width: '100%' }} />
//                     </Grid>
//                     <Grid item xs={12} sm={6} className="content-center">
//                         <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 4 }}>
//                             <Typography width={'100%'} component={"span"} fontSize={{ xs: '2rem', sm: '5rem' }} color={'#F06661'} fontWeight={700}>
//                                 {isArabic ? "!اعتذارات" : "Apologies!"}
//                             </Typography>
//                             <Typography width={'100%'} component={"span"} fontSize={{ xs: '2rem', sm: '3rem' }} color={'#FDE067'} fontWeight={700}>
//                                 {isArabic ? ".يبدو أنك لست في البلد الذي حددته. الرجاء تحديد البلد الصحيح والمحاولة مرة أخرى" : `It looks like you are not in the country that you've selected. Please select the correct country and try again`}
//                             </Typography>
//                             <Button variant="contained" onClick={handleTryAgain}
//                                 sx={{ padding: '1% 2%', borderRadius: '10px', background: "linear-gradient(180deg, #FD8484 0%,  #F06661 77%, #DF3F34 100%)", fontSize: { xs: '1rem', sm: '2rem' } }}
//                             >
//                                 {isArabic ? 'حاول ثانية' : 'Try Again'}
//                             </Button>
//                             <Typography width={'100%'} component={"span"} fontSize={{ xs: '0.8rem', sm: '1.5rem' }} color={'#FDE067'} fontWeight={500}>
//                                 {isArabic ? ":إذا كنت لا تزال تواجه مشكلة في اختيار البلد، يرجى مراسلتنا عبر البريد الإلكتروني على" : `If you continue to have issue with country selection, please email us at: `} <br />
//                                 <Typography width={'100%'} component={"span"} fontSize={{ xs: '0.8rem', sm: '1.5rem' }} color={'#FDE067'} fontWeight={900}>
//                                     contact@winwithrani.com
//                                 </Typography>
//                             </Typography>
//                         </Box>
//                     </Grid>
//                 </Grid>
//             </Grid>
//         </Grid>