import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import './App.css';
import { AppHeader, Footer, LanguageButton } from '@components';
import { AppRoutes, navigation, useAppContext } from '@utils';
import { ConfettiAnimation } from '@animations';
import { observer } from 'mobx-react';

export const App = observer(() => {
  const {
    appStore: {
      getStore: appStore, page, showConfetti, getUserIP: get_user_ip
    }
  } = useAppContext();
  const getUserIP = async () => {
    await get_user_ip();
  }
  useLayoutEffect(() => {
    getUserIP()
    // appStore.setPage = ("Error");
  }, [])
  return (
    <>
      <AppRoutes />
    </>
  );
})

