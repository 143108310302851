// import { useCallback, useEffect, useState } from "react";
import { Avatar, Box, Button, useMediaQuery, useTheme } from "@mui/material"
import './CanAnimation.css';
import { useAppContext } from "@utils";
import Can_1 from '@images/Can_1.png';
// import Chunk1 from '@images/Chunk1.png';
// import Chunk2 from '@images/Chunk2.png';
// import Chunk3 from '@images/Chunk3.png';
import Ticket from '@images/ticket.png';
import Ticket_v from '@images/ticket_v.png';
import English_boarding_pass_row from '@images/english_boarding_pass_row.png';
import Arabic_boarding_pass_row from '@images/arabic_boarding_pass_row.png';
import English_boarding_pass_uae from '@images/english_boarding_pass_uae.png';
import Arabic_boarding_pass_uae from '@images/arabic_boarding_pass_uae.png';

import chunk_explosion from '@images/chunk_explosion.png';
import CancelIcon from '@mui/icons-material/Cancel';
import { observer } from "mobx-react";

export const CanAnimation = observer(() => {
    const {
        appStore: {
            getStore: appStore, canAnimationClass, ticketAnimationClass, cancelConfetti, resetSession, chunkExplosionClass, language, userCountry
        }
    } = useAppContext();
    const theme = useTheme();
    const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
    const isUae = userCountry === "AE" || false;
    const resetState = () => {

        resetSession();
    }
    return (
        <>
            {ticketAnimationClass &&
                <Box position={'absolute'} height={'100%'} width={'100%'} zIndex={11} bottom={0} justifyContent={'flex-end'} alignItems={'center'} display={'flex'} flexDirection={'column'} >
                    <Avatar className={ticketAnimationClass ? ticketAnimationClass : 'hidden'} variant="square" src={
                        isUae ?
                            language === "Arabic" ?
                                Arabic_boarding_pass_uae
                                : English_boarding_pass_uae
                            :
                            language === "Arabic" ?
                                Arabic_boarding_pass_row
                                : English_boarding_pass_row
                    } sx={{ height: 'auto', width: { xs: '50%', sm: '20%' }, position: 'relative', bottom: { xs: 0, sm: 0 }, transform: { xs: 'rotateX(90deg)', sm: 'none' } }} />
                    {cancelConfetti && <Button onClick={resetState} disableFocusRipple disableTouchRipple sx={{ padding: 0, minWidth: 'auto', color: 'white', height: 'auto', width: 'auto', position: 'absolute', right: { xs: '3%', sm: "5%" }, top: { xs: '10%', sm: '50%' }, zIndex: 12 }} >
                        <CancelIcon />
                    </Button>}
                </Box>

            }
            {canAnimationClass &&
                <>
                    <Box overflow={'hidden'} position={'absolute'} height={'100%'} width={'100%'} zIndex={5} bottom={0} justifyContent={'flex-end'} alignItems={'center'} display={'flex'} flexDirection={'column'}  >

                        <Box position={'relative'} height={'100%'} width={'100%'} justifyContent={'flex-end'} alignItems={'center'} display={'flex'} flexDirection={'column'}>
                            {/* <AnimationComponent clockwise={false} /> */}

                            <Avatar className={canAnimationClass ? canAnimationClass : ''} variant="square" src={Can_1} sx={{ height: 'auto', width: { xs: '45%', sm: '15%', zIndex: 2 } }} />
                            <Avatar className={chunkExplosionClass ? chunkExplosionClass : 'scale0'} variant="square" src={chunk_explosion} sx={{ height: 'auto', width: { xs: '60%', sm: '15%' }, position: 'absolute', left: { xs: '15%', sm: "40%" }, top: { xs: '87%', sm: '80%' }, zIndex: 1 }} />

                        </Box>
                    </Box>
                </>
            }
        </>

    )
}
)


