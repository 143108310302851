import React from 'react';
import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom';
import { routes } from '@utils';
import { observer } from 'mobx-react';
import { UnAuthLayout } from '@components';

export const AppRoutes = observer(() => {

    return (
        <BrowserRouter>
            <Routes>
                {routes.unAuth.map(({ path, component: Component }) => (
                    <Route
                        key={path}
                        path={path}
                        element={<UnAuthLayout component={Component} />}
                    />
                ))}
                {/* {routes.auth.map(({ path, component: Component }) => (
          <Route
            key={path}
            path={path}
            element={isAuth ? <AuthLayout component={Component} /> : <Navigate replace to="/client/login" />}
          />
        ))} */}
                {routes.public.map(({ path, component: Component }) => (
                    <Route
                        key={path}
                        path={path}
                        element={<Component />}
                    />
                ))}
            </Routes>
        </BrowserRouter>
    );
});
