import { createTheme, ThemeOptions } from '@mui/material';
export const appTheme: ThemeOptions = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
  },
  typography: {
    fontFamily: 'BalooBhaiJaan2',
    h1: {
      fontSize: '3rem',
      color: '#000000',
    },
    h2: {},
    h3: {},
    h4: {},
    h5: {
      color: 'white',
    },
    h6: {
      color: 'white',
    },
    body1: {},
    body2: {},
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: "black",
        }
      }
    },
    MuiAvatar: {
      styleOverrides: {
        img: {
          width: 'auto',
          height: 'auto'
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#0176FF !important',
          '&.Mui-focused': {
            color: '#0176FF !important',
          },
        },
        asterisk: {
          color: 'rgba(253, 132, 132, 1)'
        }
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: '10px !important',
          background: 'transparent',
          // boxShadow: '0px 0px 4px 0px rgba(253, 132, 132, 1) inset',

          '&.Mui-disabled': {
            background: 'rgba(13, 31, 35, 0.05)',
            boxShadow: '0px 0px 4px 0px rgba(13, 31, 35, 0.10) inset',
          },

        },
        input: {
          '&:-webkit-autofill': {
            '-webkit-box-shadow': '0 0 0 100px white inset',
            '-webkit-text-fill-color': '#000',
            backgroundColor: '#ffffff',


          },
          '&:-internal-autofill-selected': {
            '-webkit-box-shadow': '0 0 0 100px white inset',
            '-webkit-text-fill-color': '#000',
            backgroundColor: '#ffffff'
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: '#0176FF',
          '&:hover .MuiOutlinedInput-notchedOutline': {
            border: '2px solid rgba(253, 132, 132, 0.7)',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '2px solid rgba(253, 132, 132, 1)',
          },
        },
        notchedOutline: {
          border: '1px solid rgba(253, 132, 132, 0.7)',
        },
        input: {
          color: '#0176FF',
        }
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '&&': {
            color: 'black',
            '&.Mui-checked': {
              color: 'black',
            }
          }
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
          color: '#FD8484',
          '&.Mui-checked': {
            borderColor: '#FD8484',
            color: '#FD8484',
          },
          '&.MuiCheckbox-indeterminate': {
            borderColor: '#FD8484',
            color: '#FD8484',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          textAlign: 'start'
        },
        icon: {
          color: "#DF3F34",
          fontSize: '2rem'
        }
      }
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: "4px 8px 8px"
        }
      }
    }
  },
});
