import { AccordionDetails, Avatar, Box, Button, Checkbox, CircularProgress, FormControlLabel, InputAdornment, MenuItem, TextField, Typography } from "@mui/material"
import { validationSchema, cities, useAppContext, presetCountry, phoneNumberLengths, paths } from "@utils";
import { Form, Formik } from "formik";


import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { observer } from "mobx-react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { ErrorCrossAnimation, LoadingAnimation, SuccessTickAnimation } from "@components";

export const FormContentEnglish = observer(({ animate, onSubmit }: any) => {
    const navigate = useNavigate();

    const {
        appStore: {
            country, language, sessionResponse, isEmailRequired
        }
    } = useAppContext();
    const { errMessage, status } = sessionResponse
    const phoneNumberlen = phoneNumberLengths[country]
    let emailInputTimer: any = null;
    const [emailValidationState, setEmailValidationState] = useState<ValidationState>(null);
    const handleOpenTerms = () => {
        navigate(paths.terms);
    }
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    const validateEmail = (emailValue: string) => {
        // console.log("Validating email: ", emailValue)
        return emailRegex.test(emailValue)
    }
    const handleDelayedInputChange = (e: any) => {
        if (e.target.value.length === 0) setEmailValidationState(null)
        else if (e.target.value.length > 0) {
            clearTimeout(emailInputTimer);
            const isValid = validateEmail(e.target.value);
            emailInputTimer = setTimeout(() => {
                setEmailValidationState('pending')
                emailInputTimer = setTimeout(() => {
                    setEmailValidationState(e.target.value.length === 0 ? null : isValid ? 'valid' : 'invalid');
                }, 1000);
            }, 1000);
        }
        // else setEmailValidationState(null)

    }

    const getAnimation = (
    ) => {
        if (emailValidationState === "pending")
            return <LoadingAnimation trigger={true} />
        else if (emailValidationState === "invalid")
            return <ErrorCrossAnimation trigger={true} />
        else if (emailValidationState === "valid")
            return <SuccessTickAnimation trigger={true} />
        else
            return <></>
    };
    return (
        <>
            <Formik
                initialValues={{
                    firstName: "",
                    lastName: "",
                    city: "",
                    other: "",
                    email: "",
                    countryCode: presetCountry[country].cc,
                    phoneNumber: "",
                    purchase_receipt: {} as PurchaseReceipt,
                    t_c: false,
                }}
                enableReinitialize={false}
                onSubmit={onSubmit}
            >
                {({ values, handleChange, setFieldValue }) => (
                    <Form style={{ width: '100%' }}>
                        <AccordionDetails>
                            <TextField
                                dir={"ltr"}
                                required
                                fullWidth
                                id='firstName'
                                name='firstName'
                                label={'First Name'}
                                value={values.firstName}
                                onChange={handleChange}
                                disabled={status === 'pending'}

                            />
                        </AccordionDetails>
                        <AccordionDetails>
                            <TextField
                                dir={"ltr"}
                                required
                                fullWidth
                                id='lastName'
                                name='lastName'
                                label={'Last Name'}
                                value={values.lastName}
                                onChange={handleChange}
                                disabled={status === 'pending'}
                            />
                        </AccordionDetails>
                        <AccordionDetails>
                            <TextField
                                dir={"ltr"}
                                sx={{}}
                                select
                                required
                                fullWidth
                                id='city'
                                name='city'
                                label={country === "UAE" ? 'Emirate' : 'City'}
                                value={values.city}
                                aria-label="City"
                                onChange={handleChange}
                                disabled={status === 'pending'}
                                SelectProps={{
                                    IconComponent: KeyboardArrowDownIcon,
                                    style: { direction: "ltr" }
                                }}
                            >
                                {cities[country][language].map((item: string, index: number) => {
                                    return (
                                        <MenuItem key={index} value={item}>{item}</MenuItem>
                                    )
                                })}
                            </TextField>
                        </AccordionDetails>
                        {values.city === 'Other' && <AccordionDetails>
                            <TextField
                                dir={"ltr"}
                                required
                                fullWidth
                                id='other'
                                name='other'
                                label={'Other'}
                                value={values.other}
                                onChange={handleChange}
                                disabled={status === 'pending'}
                            />
                        </AccordionDetails>}
                        <AccordionDetails sx={{ textAlign: 'start' }}>
                            <Typography marginBottom={'25px'} width={'100%'} component={"span"} fontSize={{ xs: '0.8rem', sm: '1.5rem' }} color={'#2B70EA'} fontWeight={900}>{"Please give your email address to get an instant prize."}</Typography>
                        </AccordionDetails>
                        <AccordionDetails>
                            <TextField
                                dir={"ltr"}
                                required={isEmailRequired}
                                fullWidth
                                id='email'
                                name='email'
                                label={'Email'}
                                value={values.email}
                                onChange={handleChange}
                                onInput={handleDelayedInputChange}
                                // onInputCapture={e => console.log("Oninput>> ", e)}
                                // onBlur={handleDelayedInputChange}
                                disabled={status === 'pending'}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {getAnimation()}
                                        </InputAdornment>
                                    )
                                }}
                                sx={{

                                }}
                            />
                        </AccordionDetails>
                        <AccordionDetails sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Box display={'flex'} flexDirection={'row'} width={{ xs: '29%' }} borderRadius={'10px'} height={'56px'} border={'1px solid #FD8484'} justifyContent={'space-evenly'} alignItems={'center'}>
                                <Avatar variant="square" src={presetCountry[country]["flag"]} sx={{ maxHeight: { xs: '100%', sm: '100%' }, maxWidth: { xs: '38px', sm: '73px' }, height: 'auto', width: '100%' }} />
                                <Typography width={'auto'} component={"span"} fontSize={'1rem'} color={'#2B70EA'} fontWeight={900}>{presetCountry[country]["cc"]}</Typography>
                            </Box>
                            <TextField
                                dir={"ltr"}
                                type="tel"
                                required
                                sx={{ width: { xs: '70%' }, fontSize: { xs: '0.7rem', sm: '1rem' } }}
                                id='phoneNumber'
                                name='phoneNumber'
                                label={'Phone Number'}
                                value={values.phoneNumber}
                                onChange={(e: any) => {
                                    const { value } = e.target;
                                    if (/^\d*$/.test(value)) {
                                        handleChange(e);
                                    }
                                }}
                                disabled={status === 'pending'}
                                inputProps={{ maxLength: phoneNumberlen, minLength: 7 }}
                            />
                        </AccordionDetails>
                        <AccordionDetails sx={{ textAlign: 'start' }}>
                            <Typography marginBottom={'25px'} width={'100%'} component={"span"} fontSize={{ xs: '0.8rem', sm: '1.5rem' }} color={'#2B70EA'} fontWeight={900}>{"To increase chances of winning upload your Rani purchase receipt"}</Typography>
                        </AccordionDetails>
                        <AccordionDetails sx={{ textAlign: 'end' }}>
                            <Box display={'flex'} flexDirection={'row'} width={'100%'} height={'100%'}
                                justifyContent={'space-evenly'} alignItems={'center'} textAlign={'start'} border={'1px solid rgba(253, 132, 132, 0.7)'}
                                padding={'2%'} borderRadius={'10px'} boxShadow={'0 0 2px 1px rgba(253, 132, 132, 1)'}
                            >
                                {values.purchase_receipt?.name ?
                                    <Typography width={'70%'} component={"span"} fontSize={'1rem'} color={'#0176FF'} fontWeight={900} sx={{ userSelect: 'none' }}>
                                        {values.purchase_receipt?.name}
                                    </Typography>
                                    :
                                    <Typography width={'70%'} component={"span"} fontSize={'1rem'} color={'#0176FF'} fontWeight={900} sx={{ userSelect: 'none' }}>
                                        {"Receipt"}

                                    </Typography>
                                }
                                <Button variant="contained" component='label' disabled={status === 'pending'}
                                    sx={{ width: '30%', padding: '1% 2%', borderRadius: '10px', background: "linear-gradient(180deg, #FD8484 0%,  #F06661 77%, #DF3F34 100%)", fontSize: { xs: '0.75rem', sm: '1rem' } }}
                                >
                                    {"Choose File"}
                                    <input
                                        hidden
                                        id='purchase_receipt'
                                        name='purchase_receipt'
                                        accept='image/*'
                                        type='file'
                                        onChange={(event: any) => {
                                            setFieldValue("purchase_receipt", event.target.files[0]);
                                        }}
                                    />
                                </Button>
                            </Box>
                            {<Typography width={'100%'} component={"span"} fontSize={{ xs: '0.8rem', sm: '1.5rem' }} color={'#0176FF'} fontWeight={900}>
                                <Typography width={'100%'} component={"span"} fontSize={{ xs: '0.8rem', sm: '1.5rem' }} color={'rgba(253, 132, 132, 1)'} fontWeight={900}>
                                    *
                                </Typography>
                                Only Image Formats Allowed
                            </Typography>
                            }
                        </AccordionDetails>
                        <AccordionDetails sx={{ display: 'flex', justifyContent: 'flex-start', }}>
                            <FormControlLabel required control={
                                <Checkbox
                                    required
                                    id='t_c'
                                    name='t_c'
                                    checked={values.t_c}
                                    onChange={handleChange}
                                    disabled={status === 'pending'}
                                />
                            } label={
                                <Typography width={'100%'} component={"span"} fontSize={{ xs: '0.8rem', sm: '1.5rem' }} color={'#0176FF'} fontWeight={400}>I agree to all &nbsp;
                                    <Typography onClick={handleOpenTerms} width={'100%'} component={"span"} fontSize={{ xs: '0.8rem', sm: '1.5rem' }} color={'#0176FF'} fontWeight={900}>Terms & Conditions</Typography>
                                </Typography>
                            }
                            />
                        </AccordionDetails>
                        <Button variant="contained" type={'submit'} fullWidth disabled={!isEmailRequired && values.email === "" ? false : (emailValidationState !== "valid" || status === 'pending')} endIcon={
                            status === "pending" && <CircularProgress sx={{ width: '100%' }} />
                        }
                            sx={{ padding: '2% 5%', borderRadius: '10px', background: "linear-gradient(180deg, #FD8484 0%,  #F06661 77%, #DF3F34 100%)", fontSize: { xs: '1rem', sm: '2rem' } }}
                        >
                            {"Submit and have a summmer Blast !!"}
                        </Button>
                        <AccordionDetails sx={{ display: 'flex', justifyContent: 'flex-start', }}>
                            {
                                <Typography width={'100%'} component={"span"} fontSize={{ xs: '0.8rem', sm: '1.5rem' }} color={'#0176FF'} fontWeight={400}>For any queries, you may contact us at: <br />
                                    <Typography width={'100%'} component={"span"} fontSize={{ xs: '0.8rem', sm: '1.5rem' }} color={'#0176FF'} fontWeight={900}>contact@winwithrani.com</Typography>
                                </Typography>
                            }
                        </AccordionDetails>
                    </Form>
                )}
            </Formik >
            {/* {status === 'ok' && animate()}
            {status === '404' && animate()} */}
        </>
    )
})