import { store } from '@store';
import React, { createContext, useContext, ReactNode } from 'react';

export const AppContext = createContext(store);

export const AppProvider = ({ children }: { children: ReactNode }) => {
    return (
        <AppContext.Provider value={store}>
            {children}
        </AppContext.Provider>
    );
};
export const useAppContext = () => useContext(AppContext);

