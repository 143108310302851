import React, { useContext } from "react";
import { useAppContext } from "@utils";
import { Avatar, Box, Grid, Typography } from "@mui/material";
import CS_bg1 from '@images/CS_bg1&2.png';
import CS_WinRani from '@images/CS_WinRani.png';
import CS_WinRani_arabic from '@images/CS_WinRani_arabic.png';

import CS_RaniJuice from '@images/CS_RaniJuice.png';

import Chuncks_pack_l from '@images/Chuncks_pack_l.png';
import Chuncks_pack_r from '@images/Chuncks_pack_r.png';
import { observer } from "mobx-react";


export const ComingSoon = observer(() => {
    const {
        appStore: {
            getStore: appStore, language, client
        }
    } = useAppContext();
    const isArabic = language === "Arabic";

    return (
        <Grid container className="min-h-screen relative content-center">
            <Grid item xs={12} minHeight={{ xs: '50vh', sm: '100vh' }} className="bg-cover content-center" sx={{ backgroundImage: `url(${CS_bg1})` }}>
                <Grid container sx={{ mt: 5 }}>
                    <Grid item xs={12} sm={6}>
                        <Avatar variant="square" src={CS_RaniJuice} sx={{ height: 'auto', width: '100%' }} />
                    </Grid>
                    <Grid item xs={12} sm={6} className="content-center">
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <Typography width={'100%'} component={"span"} fontSize={{ xs: '2rem', sm: '4rem' }} color={'white'} fontWeight={700}>
                                {isArabic ? "!! انتظرونا" : "Coming Soon!!"}
                            </Typography>
                            <Typography width={'100%'} component={"span"} fontSize={{ xs: '2rem', sm: '3rem' }} color={'white'} fontWeight={700}>
                                {isArabic ? "!  اشرب، امسح، واربح" : "Drink, Scan and Win!"}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
})