import { Box, MenuItem, Select } from "@mui/material";
import { useAppContext } from "@utils";
import { observer } from "mobx-react";
import React from "react";

export const LanguageButton = observer(() => {
    const {
        appStore: {
            getStore: appStore, language
        }
    } = useAppContext();
    return (
        <Box position={'absolute'} top={0} right={{ xs: "5%", sm: '1%' }} zIndex={9}>
            <Select
                sx={{
                    height: "100%",
                    background: '#2B70EA',
                    '& .MuiSelect-select': {
                        color: 'white',
                        paddingTop: '1%',
                        paddingBottom: '1%',

                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        border: '1px solid #ffffff'
                    }
                }}
                value={language}
                onChange={e => appStore.setLanguage = (e.target.value as Language)}

            >
                <MenuItem value={"Arabic"}>{"عربي"}</MenuItem>
                <MenuItem value={"English"}>English</MenuItem>
            </Select>
        </Box>

    );
});
