import { paths, useAppContext } from '@utils';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const TermsAndConditionsPage = observer(() => {
    const navigate = useNavigate()
    const [content, setContent] = useState('');
    const {
        appStore: {
            userCountry, language
        }
    } = useAppContext();
    const handleBack = () => {
        navigate(paths.home);
    }
    const fileNames: any = {
        "KSA": {
            "E": <Terms_KSA_E handleBack={handleBack} />,
            "A": <Terms_KSA_A handleBack={handleBack} />,
        },
        "ROW": {
            "E": <Terms_ROW_E handleBack={handleBack} />,
            "A": <Terms_ROW_A handleBack={handleBack} />,
        }
    }
    const terms_region: any = userCountry === "KSA" ? 'KSA' : "ROW"
    const languageInitials: any = language[0];
    const termsComponent: any = fileNames[terms_region][languageInitials];
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])



    return (
        <>
            {termsComponent}
            {/* <Email_UAE_Arabic /> */}
        </>
    );
});


const Terms_ROW_E = ({ handleBack }: any) => {
    return (
        <html lang="en">
            <head>
                <meta charSet="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <title>Terms and Conditions</title>
                <style>
                    {`
                        body {
                            font-family: Arial, sans-serif;
                            margin: 0;
                            padding: 20px;
                            line-height: 1.6;
                            background-color: #2B70EA;
                        }

                        h1, h2 {
                            color: #333;
                        }

                        h1 {
                            text-align: center;
                        }

                        .content {
                            max-width: 800px;
                            margin: auto;
                            padding: 20px;
                            background: #fde067;
                            border-radius: 8px;
                        }

                        ul {
                            list-style-type: decimal;
                            margin: 20px 0;
                            padding: 0 20px;
                        }

                        p {
                            margin: 10px 0;
                        }

                        .arrow-back {
                            cursor: pointer;
                            display:flex;
                            width:auto;
                            justify-content:flex-start;
                        }
                    `}
                </style>
            </head>
            <body>
                <div className="content">
                    <a className="arrow-back" onClick={handleBack}>
                        <img src="./Arrow_back.png" alt="Arrow Back" />
                    </a>
                    <h1>Terms and Conditions</h1>

                    <h2>How to Enter:</h2>
                    <ul>
                        <li>You must Scan the QR code of the competition found at the store display / shelf or enter the website (<a href="http://www.winwithrani.com" target="_blank" rel="noopener noreferrer">www.winwithrani.com</a>)</li>
                        <li>Open the link, enter your details, and register</li>
                        <li>Accept the terms & conditions</li>
                    </ul>

                    <h2>Terms and Conditions:</h2>
                    <ul>
                        <li>The purchase is not required to enter the competition.</li>
                        <li>The conditions of the competition are subject to the laws and regulations in force in the Kingdom of Saudi Arabia.</li>
                        <li>Chambers of Commerce employees and their first-degree families are not entitled to participate in competitions.</li>
                        <li>Employees of the operating company and their first-degree families are not entitled to participate in competitions.</li>
                        <li>Advertising and publicity agency employees and their first-degree families are not entitled to participate in competitions.</li>
                        <li>The employees of the company that owns the competition and their families of the first degree are not entitled to participate in the competition related to it.</li>
                        <li>The employees of the participating malls and their families of the first degree are not entitled to participate in the respective competition.</li>
                        <li>If it is found that there is more than one account for one person, all accounts will be canceled without exception, including the main account, in order to demonstrate his circumvention of the systems, and he will be deprived of all the prizes he won under these accounts.</li>
                        <li>For residents of the Kingdom of Saudi Arabia, write the full name in Arabic as it is found in the regular identity (national ID, resident ID).</li>
                        <li>All winners must have a valid ID upon receiving the prize.</li>
                        <li>Changing an account information that has participated in a contest that has not yet been drawn, will deprive its owner of winning even if he wins.</li>
                        <li>Entering the competition is an implied acceptance of its terms and conditions.</li>
                        <li>The prize is not exchangeable for cash of any kind, and is non-transferable, non-negotiable, and cannot be subject to mortgage for any third party.</li>
                        <li>In the case of refusal of the gift, the gift is forfeited and another winner will be drawn.</li>
                        <li>Winners will be chosen from a random draw of entries received in accordance with these Terms and Conditions.</li>
                        <li>Aujan Beverages Co. may request photography with the winner for marketing purposes and the winner will be posted on social networking sites.</li>
                    </ul>
                </div>
            </body>
        </html>
    );
};

const Terms_ROW_A = ({ handleBack }: any) => {
    return (
        <html lang="ar" dir="rtl">
            <head>
                <meta charSet="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <title>الشروط والأحكام</title>
                <style>
                    {`
                        body {
                            font-family: Arial, sans-serif;
                            margin: 0;
                            padding: 20px;
                            line-height: 1.6;
                            background-color: #2B70EA;
                            text-align: right;
                        }

                        h1, h2 {
                            color: #333;
                        }

                        h1 {
                            text-align: center;
                        }

                        .content {
                            max-width: 800px;
                            margin: auto;
                            padding: 20px;
                            background: #fde067;
                            border-radius: 8px;
                        }

                        ul {
                            list-style-type: decimal;
                            margin: 20px 0;
                            padding: 0 20px;
                            text-align: right;
                            direction: rtl;
                        }

                        p {
                            margin: 10px 0;
                        }

                        .arrow-back {
                            cursor: pointer;
                            display:flex;
                            width:auto;
                            justify-content:flex-end;
                        }
                    `}
                </style>
            </head>
            <body>
                <div className="content">
                    <a className="arrow-back" onClick={handleBack} >
                        <img src="./Arrow_back.png" alt="Arrow Back" />
                    </a>
                    <h1>الشروط والأحكام</h1>

                    <h2>:طريقة الإشتراك</h2>
                    <ul>
                        <li>قم امسح باركود المسابقة المعروض على رف منتجات راني في الأسواق أو قم بزيارة الرابط (<a href="http://www.winwithrani.com" target="_blank" rel="noopener noreferrer">www.winwithrani.com</a>)</li>
                        <li>افتح الرابط وعبئ بياناتك ثم سجل</li>
                        <li> وافق على جميع الشروط والأحكام</li>
                    </ul>

                    <h2>:لشروط والأحكام</h2>
                    <ul>
                        <li>لا يشترط الشراء للدخول في المسابقة.</li>
                        <li>تخضع شروط المسابقة للقوانين والأنظمة الجارية في المملكة العربية السعودية.</li>
                        <li>لا يحق لموظفي الغرف التجارية وعائلاتهم من الدرجة الأولى المشاركة في المسابقات.</li>
                        <li>لا يحق لموظفي الشركة المشغلة للنظام وعائلاتهم من الدرجة الأولى المشاركة في المسابقات.</li>
                        <li>لا يحق لموظفي وكالة الإعلان والدعاية وعائلاتهم من الدرجة الأولى المشاركة في المسابقات.</li>
                        <li>لا يحق لموظفي الشركة صاحبة المسابقة وعائلاتهم من الدرجة الأولى المشاركة في المسابقة الخاصة بها.</li>
                        <li>لا يحق لموظفي المراكز التجارية المشاركة وعائلاتهم من الدرجة الأولى المشاركة في المسابقة الخاصة بها.</li>
                        <li>في حال تبين وجود أكثر من حساب لشخص واحد فسوف يتم إلغاء جميع الحسابات بلا استثناء بما فيها الحساب الرئيسي
                            وذلك لبيان تحايله على الأنظمة و سيحرم من جميع الجوائز التي ربحها تحت هذه الحسابات.</li>
                        <li>للمقيمين في المملكة العربية السعودية كتابة الاسم بالكامل باللغة العربية كما هو موجود في الهوية النظامية
                            (الهوية الوطنية، هوية مقيم).</li>
                        <li>يجب على جميع الفائزين ان تكون الهوية سارية المفعول عند استلام الجائزة.</li>
                        <li>تغيير بيانات حساب قام بالمشاركه في مسابقه لم يتم السحب عليها بعد، يحرم صاحبه من الفوز فيها حتى وان فاز.
                        </li>
                        <li>يعتبر الاشتراك في المسابقة بمثابة قبول ضمني لشروطها وأحكامها.</li>
                        <li>الدخول في المسابقة يعد موافقة ضمنية لشروطها وأحكامها.</li>
                        <li>لا يمكن استبدال الجائزة بقيمة نقدية أو بأي شيء بديل. الجوائز غير قابلة للتحويل، وهي غير قابلة للتفاوض
                            وغير قابلة للرهن إلى أي طرف ثالث.</li>
                        <li>في حال رفض المتسابق الجائزة فسوف يتم استبعاده وإعادة السحب مرة أخرى.</li>
                        <li>سيتم السحب إلكترونياً على الرابح من المطابقين للشروط والأحكام، بوجود مندوب الغرفة التجارية </li>
                    </ul>
                </div>
            </body>
        </html>
    );
};

const Terms_KSA_E = ({ handleBack }: any) => {
    return (
        <html lang="en">
            <head>
                <meta charSet="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <title>Terms and Conditions</title>
                <style>
                    {`
                        body {
                            font-family: Arial, sans-serif;
                            margin: 0;
                            padding: 20px;
                            line-height: 1.6;
                            background-color: #2B70EA;
                        }

                        h1, h2 {
                            color: #333;
                        }

                        h1 {
                            text-align: center;
                        }

                        .content {
                            max-width: 800px;
                            margin: auto;
                            padding: 20px;
                            background: #fde067;
                            border-radius: 8px;
                        }

                        ul {
                            list-style-type: decimal;
                            margin: 20px 0;
                            padding: 0 20px;
                        }

                        p {
                            margin: 10px 0;
                        }

                        .arrow-back {
                            cursor: pointer;
                            display:flex;
                            width:auto;
                            justify-content:flex-start;
                        }
                    `}
                </style>
            </head>
            <body>
                <div className="content">
                    <a className="arrow-back" onClick={handleBack}>
                        <img src="./Arrow_back.png" alt="Arrow Back" />
                    </a>
                    <h1>Terms and Conditions</h1>

                    <h2>How to Enter:</h2>
                    <ul>
                        <li>You must Scan the QR code of the competition found at the store display / shelf or enter the website (<a
                            href="http://www.winwithrani.com" target="_blank" rel="noopener noreferrer">www.winwithrani.com</a>)</li>
                        <li>Open the link, enter your details, and register</li>
                        <li>Accept the terms & conditions</li>
                    </ul>

                    <h2>Terms and Conditions (eCopon):</h2>
                    <ul>
                        <li>The purchase is not required to enter the competition.</li>
                        <li>The conditions of the competition are subject to the laws and regulations in force in the Kingdom of
                            Saudi Arabia.</li>
                        <li>Chambers of Commerce employees and their first-degree families are not entitled to participate in
                            competitions.</li>
                        <li>Employees of the operating company and their first-degree families are not entitled to participate in
                            competitions.</li>
                        <li>Advertising and publicity agency employees and their first-degree families are not entitled to
                            participate in competitions.</li>
                        <li>The employees of the company that owns the competition and their families of the first degree are not
                            entitled to participate in the competition related to it.</li>
                        <li>The employees of the participating malls and their families of the first degree are not entitled to
                            participate in the respective competition.</li>
                        <li>The name must be identical to the legal identity in force in the Kingdom of Saudi Arabia.</li>
                        <li>If it is found that there is more than one account for one person, all accounts will be canceled without
                            exception, including the main account, in order to demonstrate his circumvention of the systems, and he
                            will be deprived of all the prizes he won under these accounts.</li>
                        <li>For residents of the Kingdom of Saudi Arabia, write the full name in Arabic as it is found in the
                            regular identity (national ID, resident ID).</li>
                        <li>All winners must have a valid ID upon receiving the prize.</li>
                        <li>Changing an account information that has participated in a contest that has not yet been drawn, will
                            deprive its owner of winning even if he wins.</li>
                        <li>In the event that the name of the winner does not match the name on the official ID approved in the
                            Kingdom of Saudi Arabia, it will be disqualified and the drawing will be re-drawn again.</li>
                        <li>Fill out the declaration with correct data according to the official identity (national ID, resident ID)
                            and sent by the (eCopon) system to the winner’s mobile in the form of a link.</li>
                        <li>Reading (award receipt card) created from the (eCopon) system by the competition organizing company is
                            considered an acknowledgment by the winner of receiving the award.</li>
                        <li>The Smart Link Information Technology Co., Ltd. (eCopon) has the right to file a fraud case against
                            people who filled out the acknowledgment form with incorrect data that does not match the legal identity
                            (national ID, Gulf ID, resident ID, visitor ID, passport).</li>
                        <li>Entering the competition is an implied acceptance of its terms and conditions.</li>
                        <li>The Smart Link Information Technology Co., Ltd. (eCopon) is the operator of the competition system only.
                            It has no authority over the type or quantity of prizes, delivery or exchange of prizes, but this is
                            done by the company, sponsor or organizer of the competition.</li>
                        <li>Smart Link Information Technology Co., Ltd. (eCopon) is an operator of the competition system only, and
                            has no relationship in the event that one of the parties breaches any of the terms of receiving (the
                            winner) or delivering (the facility) the prize.</li>
                        <li>Apple is not responsible for any of these competitions that are present in the eCopon system and
                            application, and it has nothing to do in any way with these competitions.</li>
                        <li>The prize is not exchangeable for cash of any kind, and is non-transferable, non-negotiable, and cannot
                            be subject to mortgage for any third party.</li>
                        <li>In the case of refusal of the gift, the gift is forfeited and another winner will be drawn.</li>
                        <li>The opening date of the competition “win with Rani” is on 5 July 2024.</li>
                        <li>The closing date of the Prize Draw is 23:59 on 5 October 2024. Entries received outside this time period
                            will not be considered.</li>
                        <li>Winners will be chosen from a random draw of entries received in accordance with these Terms and
                            Conditions. The draw will be performed electronically in witness with the Chamber of Commerce
                            representative.</li>
                        <li>The draw will take place on 5 August, 5 September, & 6 October 2024 respectively.</li>
                        <li>Aujan Beverages Co. may request photography with the winner for marketing purposes and the winner will
                            be posted on social networking sites.</li>
                    </ul>
                </div>
            </body>
        </html>
    );
}

const Terms_KSA_A = ({ handleBack }: any) => {
    return (
        <html lang="ar" dir="rtl">
            <head>
                <meta charSet="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <title>الشروط والأحكام</title>
                <style>
                    {`
                        body {
                            font-family: Arial, sans-serif;
                            margin: 0;
                            padding: 20px;
                            line-height: 1.6;
                            background-color: #2B70EA;
                            text-align: right;
                        }

                        h1, h2 {
                            color: #333;
                        }

                        h1 {
                            text-align: center;
                        }

                        .content {
                            max-width: 800px;
                            margin: auto;
                            padding: 20px;
                            background: #fde067;
                            border-radius: 8px;
                        }

                        ul {
                            list-style-type: decimal;
                            margin: 20px 0;
                            padding: 0 20px;
                            text-align: right;
                            direction: rtl;
                        }

                        p {
                            margin: 10px 0;
                        }

                        .arrow-back {
                            cursor: pointer;
                            display:flex;
                            width:auto;
                            justify-content:flex-end;
                        }
                    `}
                </style>
            </head>
            <body>
                <div className="content">
                    <a className="arrow-back" onClick={handleBack} >
                        <img src="./Arrow_back.png" alt="Arrow Back" />
                    </a>
                    <h1>الشروط والأحكام</h1>

                    <h2>:طريقة الإشتراك</h2>
                    <ul>
                        <li>قم امسح باركود المسابقة المعروض على رف منتجات راني في الأسواق أو قم بزيارة الرابط (<a
                            href="http://www.winwithrani.com" target="_blank" rel="noopener noreferrer">www.winwithrani.com</a>)</li>
                        <li>افتح الرابط وعبئ بياناتك ثم سجل</li>
                        <li>وافق على جميع الشروط والأحكام</li>
                    </ul>

                    <h2>:لشروط والأحكام</h2>
                    <ul>
                        <li>لا يشترط الشراء للدخول في المسابقة.</li>
                        <li>تخضع شروط المسابقة للقوانين والأنظمة الجارية في المملكة العربية السعودية.</li>
                        <li>لا يحق لموظفي الغرف التجارية وعائلاتهم من الدرجة الأولى المشاركة في المسابقات.</li>
                        <li>لا يحق لموظفي الشركة المشغلة للنظام وعائلاتهم من الدرجة الأولى المشاركة في المسابقات.</li>
                        <li>لا يحق لموظفي وكالة الإعلان والدعاية وعائلاتهم من الدرجة الأولى المشاركة في المسابقات.</li>
                        <li>لا يحق لموظفي الشركة صاحبة المسابقة وعائلاتهم من الدرجة الأولى المشاركة في المسابقة الخاصة بها.</li>
                        <li>لا يحق لموظفي المراكز التجارية المشاركة وعائلاتهم من الدرجة الأولى المشاركة في المسابقة الخاصة بها.</li>
                        <li>يجب ان يكون الاسم مطابق للهوية النظامية المعمول بها في المملكة العربية السعودية.</li>
                        <li>في حال تبين وجود أكثر من حساب لشخص واحد فسوف يتم إلغاء جميع الحسابات بلا استثناء بما فيها الحساب الرئيسي
                            وذلك لبيان تحايله على الأنظمة و سيحرم من جميع الجوائز التي ربحها تحت هذه الحسابات.</li>
                        <li>للمقيمين في المملكة العربية السعودية كتابة الاسم بالكامل باللغة العربية كما هو موجود في الهوية النظامية
                            (الهوية الوطنية، هوية مقيم).</li>
                        <li>يجب على جميع الفائزين ان تكون الهوية سارية المفعول عند استلام الجائزة.</li>
                        <li>تغيير بيانات حساب قام بالمشاركه في مسابقه لم يتم السحب عليها بعد، يحرم صاحبه من الفوز فيها حتى وان فاز.
                        </li>
                        <li>في حال لم يتطابق اسم الفائز مع الاسم الموجود بالهوية النظامية المعتمدة في المملكة العربية السعودية يتم
                            استبعاده وإعادة السحب مرة أخرى.</li>
                        <li>تعبئة الإقرار ببيانات صحيحة حسب الهوية النظامية (الهوية الوطنية، الهوية الخليجية، هوية مقيم، هوية زائر،
                            جواز السفر) والمرسل من قبل نظام (eCopon) الى جوال الفائز على شكل رابط.</li>
                        <li>قراءة (بطاقة استلام جائزة) المنشأة من نظام (eCopon) من قبل الشركة المنظمة للمسابقة يعد اقرارا من قبل
                            الفائز باستلام الجائزة.</li>
                        <li>يحق لشركة الارتباط الذكي لتقنية المعلومات المحدودة (eCopon) رفع دعوى تحايل على الأشخاص الذين قاموا
                            بتعبئة نموذج الإقرار ببيانات غير صحيحة ولا تطابق الهوية النظامية (الهوية الوطنية، الهوية الخليجية، هوية
                            مقيم، هوية زائر، جواز السفر).</li>
                        <li>الدخول في المسابقة يعد موافقة ضمنية لشروطها وأحكامها.</li>
                        <li>شركة الارتباط الذكي لتقنية المعلومات المحدودة (eCopon) هي شركة مشغلة لنظام المسابقات فقط، ليس لها أي
                            صلاحية على نوع أو كمية الجوائز أو تسليمها أو استبدالها وإنما يتم ذلك عن طريق الشركة أو الجهة راعية
                            المسابقة أو المنظمة لها.</li>
                        <li>شركة الارتباط الذكي لتقنية المعلومات المحدودة (eCopon) هي شركة مشغلة لنظام المسابقات فقط، ليس لها أي
                            علاقة في حال أخل أحد الطرفين باي بند من بنود استلام (الفائز) أو تسليم (المنشأة) الجائزة.</li>
                        <li>أبل ليست مسئوله عن أي مسابقة من هذه المسابقات والمتواجدة في نظام وتطبيق eCopon، وانه ليس لها اي علاقة
                            باي شكل من الاشكال بهذه المسابقات.</li>
                        <li>لا يمكن استبدال الجائزة بقيمة نقدية أو بأي شيء بديل. الجوائز غير قابلة للتحويل، وهي غير قابلة للتفاوض
                            وغير قابلة للرهن إلى أي طرف ثالث.</li>
                        <li>في حال رفض المتسابق الجائزة فسوف يتم استبعاده وإعادة السحب مرة أخرى.</li>
                        <li>تبدأ المسابقة "اربح مع راني" من 5 يوليو 2024.</li>
                        <li>تاريخ انتهاء العرض هو 5 اكتوبر 2024 الساعة 23:59 مساءً. أي مشاركة بعد الوقت المحدد لن تحتسب.</li>
                        <li>سيتم السحب إلكترونياً على الرابح من المطابقين للشروط والأحكام، بوجود مندوب الغرفة التجارية.</li>
                        <li>فالسحب سيتم بتاريخ 5 اغسطس, 5 سبتيمبر, و 6 اكتوبر 2024.</li>
                        <li>يحق لشركة العوجان للمرطبات بأخذ صور للفائز ورفعها على مواقع التواصل الاجتماعي.</li>
                    </ul>
                </div>
            </body>
        </html>
    );
}

const Email_UAE = () => {
    const links = ["Link 1", "Link 2"]
    const steps = [
        "Buy any Rani Product",
        "Go to www.winwithrani.com or scan this QR Code.",
        "Enter your details and Rani purchase receipt.",
        "Enter the Raffle Draw for a chance to WIN! *"
    ]
    const vouchers = ["riyadh-0"];
    return (
        <html lang="en">
            <head>
                <meta charSet="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <title>Terms and Conditions</title>
                <style>
                    {`
                        body {
                            font-family: Arial, sans-serif;
                            margin: 0;
                            padding: 20px;
                            line-height: 1.6;
                            background-color: #fffff;
                        }
                        
                        h1, h3 {
                            color: #333;
                        }
                        
                        h1 {
                            text-align: start;
                        }
                        h2 {
                            font-weight: 700;
                        }
  
                        .content {
                            max-width: 800px;
                            margin: auto;
                            padding: 20px;
                            background: #ffffff;
                            border-radius: 8px;
                        }
  
                        ul {
                            list-style-type: decimal;
                            margin: 20px 0;
                            padding: 0 20px;
                        }
                        
                        p {
                            margin: 10px 0;
                        }
  
                        .blue-links{
                            color:#2B70EA;
                        }
                        .list-container {
                            display:flex;
                            flex-direction:row;
                            align-items:center;
                        }
                        .boarding-pass {
                            margin-left:30px;
                        }
                        .inline-flex {
                            display:inline-flex
                        }
                        .end-align {
                            text-align:end;
                        }
                        .italic {
                            font-style:italic;
                        }
                        .noon-voucher-icon {
                        width:60px;
                        height:30px
                        }
                    `}
                </style>
            </head>
            <body>
                <div className="content">
                    <h2>Thank you for your participation into the Rani Summer Raffle Draw 2024! Your entry has been submitted. </h2>
                    <h3>Wait! 😊</h3>

                    <h3>
                        You can still enjoy these exciting instant Gifts from Rani and add EXTRA to your Summer.
                        <br />
                        {vouchers && (
                            <div className='inline-flex voucher-container'>
                                <div>
                                    <h3 className='inline-flex'>
                                        A Voucher for a Flat AED 15/- OFF on Rani products &nbsp;
                                        <img className='noon-voucher-icon' src='https://rani-live.s3.us-east-2.amazonaws.com/static-images/email/noon_voucher_icon.jpg' />
                                        &nbsp; from
                                    </h3>
                                    <h3>
                                        Your One- Time Noon code is : {vouchers[0]} <br />
                                        -----------------
                                    </h3>
                                    <h2> And that’s not all!</h2>
                                    <h2> You have also won 2 exclusive Wallpapers from Rani!</h2>
                                </div>
                                <br />
                            </div>
                        )}
                        <br />
                        To download your prizes click on the below links
                    </h3>
                    <ul>
                        {links.map((item, index) => {
                            return (
                                <li className='blue-links' key={index}>{item}</li>
                            )
                        })}
                    </ul>
                    <h2>
                        Increase your winning chances by following the below steps:
                    </h2>
                    <div className='list-container'>
                        <ul>
                            {steps.map((item, index) => {
                                if (index === 1)
                                    return (
                                        <li>
                                            Go to &nbsp;
                                            <a key={index} className='blue-links' href={'www.winwithrani.com'}>
                                                www.winwithrani.com
                                            </a>
                                            &nbsp; or scan this QR Code.
                                        </li>
                                    )
                                else
                                    return (
                                        <li key={index}>{item}</li>
                                    )
                            })}
                        </ul>
                        <img width={100} height={100} src='https://rani-live.s3.us-east-2.amazonaws.com/static-images/email/Riyadh_QR.png' />
                    </div>
                    <img className='boarding-pass' width={200} height={400} src='https://rani-live.s3.us-east-2.amazonaws.com/static-images/email/Boarding_Pass_English.png' />
                    <h3>
                        Visit us for more prizes in store .

                    </h3>
                    <h3 className='inline-flex'>
                        Go to &nbsp;
                        <img src='https://rani-live.s3.us-east-2.amazonaws.com/static-images/email/insta_icon.jpg' width={25} height={25} />
                        &nbsp; @drinkrani for more details.
                    </h3>
                    <br />
                    <br />
                    <h2>
                        Add EXTRA to your Summer !
                    </h2>
                    <br />
                    <h2>
                        Rani Team
                    </h2>

                    <h3 className='end-align italic'>
                        *Terms and Conditions apply
                    </h3>
                </div>
            </body>
        </html>
    )
}

const Email_UAE_Arabic = () => {
    const links = ["الرابط  1", "الرابط  2"]
    const steps = [
        "قم بشراء أي منتج من منتجات راني",
        " أو قم بمسح رمز الاستجابة السريعة هذا www.winwithrani.com انتقل إلى",
        ".أدخل التفاصيل الخاصة بك وإيصال الشراء راني",
        "*.أدخل السحب للحصول على فرصة الفوز"
    ]
    const vouchers = ["riyadh-0"];
    return (
        <html lang="ar" dir="rtl">
            <head>
                <meta charSet="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <title>Terms and Conditions</title>
                <style>
                    {`
                        body {
                            font-family: Arial, sans-serif;
                            margin: 0;
                            padding: 20px;
                            line-height: 1.6;
                            background-color: #fffff;
                        }
                        
                        h1, h3 {
                            color: #333;
                        }
                        
                        h1 {
                            text-align: start;
                        }
                        h2 {
                            font-weight: 700;
                        }
  
                        .content {
                            max-width: 800px;
                            margin: auto;
                            padding: 20px;
                            background: #ffffff;
                            border-radius: 8px;
                        }
  
                        ul {
                            list-style-type: decimal;
                            margin: 20px 0;
                            padding: 0 20px;
                            text-align: right;
                            direction: rtl;
                        }
                        
                        p {
                            margin: 10px 0;
                        }
  
                        .blue-links{
                            color:#2B70EA;
                        }
                        .list-container {
                            display:flex;
                            flex-direction:row;
                            align-items:center;
                        }
                        .boarding-pass {
                            margin-left:30px;
                        }
                        .inline-flex {
                            display:inline-flex
                        }
                        .end-align {
                            text-align:end;
                        }
                        .italic {
                            font-style:italic;
                        }
                        .noon-voucher-icon {
                        width:60px;
                        height:30px
                        }
                    `}
                </style>
            </head>
            <body>
                <div className="content">
                    <h1>.شكرًا لك على مشاركتك في سحب راني الصيفية لعام  2024!  تم ارسال مشاركتم </h1>
                    <h3> 😊 !انتظر  </h3>

                    <h3>
                        .لا يزال بإمكانك الاستمتاع بهذه الهدايا الفورية المثيرة من راني وإضافة المزيد إلى صيفك
                        <br />
                        {vouchers && (
                            <div className='inline-flex voucher-container'>
                                <div>
                                    <h3 className='inline-flex'>
                                        استمتع بقسيمة خصم ثابت بقيمة 15 درهمًا إماراتيًا على منتجات راني من نون &nbsp;
                                        <img className='noon-voucher-icon' src='https://rani-live.s3.us-east-2.amazonaws.com/static-images/email/noon_voucher_icon.jpg' />
                                        &nbsp;
                                    </h3>
                                    <h3>
                                        رمز نون الخاص بك لمرة واحدة ه :{vouchers[0]}<br />
                                        -----------------
                                    </h3>
                                    <h2> ! و هذا ليس كل شيء</h2>

                                </div>
                                <br />
                            </div>
                        )}
                        <br />
                        <h2> ! و هذا ليس كل شيء</h2>
                        :لتحميل جوائزك اضغط على الروابط أدناه
                    </h3>
                    <ul>
                        {links.map((item, index) => {
                            return (
                                <li className='blue-links' key={index}>{item}</li>
                            )
                        })}
                    </ul>
                    <h2>
                        !قم بزيادة فرصك للفوز بمجرد اتباع الخطوات التالية
                    </h2>
                    <div className='list-container'>
                        <ul>
                            {steps.map((item, index) => {
                                if (index === 1)
                                    return (
                                        <li>
                                            انتقل إلى &nbsp;
                                            <a key={index} className='blue-links' href={'www.winwithrani.com'}>
                                                www.winwithrani.com
                                            </a>
                                            &nbsp; أو قم بمسح رمز الاستجابة السريعة هذا.
                                        </li>
                                    )
                                else
                                    return (
                                        <li key={index}>{item}</li>
                                    )
                            })}
                        </ul>
                        <img width={100} height={100} src='https://rani-live.s3.us-east-2.amazonaws.com/static-images/email/Riyadh_QR.png' />
                    </div>
                    <img className='boarding-pass' width={200} height={400} src='https://rani-live.s3.us-east-2.amazonaws.com/static-images/email/Boarding_Pass_English.png' />
                    <h3>
                        قم بزيارتنا للحصول على المزيد من الجوائز في المتجر
                    </h3>
                    <h3 className='inline-flex'>
                        و انقر على
                        &nbsp;
                        <img src='https://rani-live.s3.us-east-2.amazonaws.com/static-images/email/insta_icon.jpg' width={25} height={25} />
                        &nbsp; drinkrani@ لمزيد من التفاصيل  اذهب الى  انستغرام
                    </h3>
                    <br />
                    <br />
                    <h2>
                        زيد صيفك حبة  إ
                    </h2>
                    <br />
                    <h2>
                        فريق راني
                    </h2>

                    <h3 className='end-align italic'>
                        *تطبق الشروط والأحكام *تطبق الشروط والأحكام

                    </h3>
                </div>
            </body>
        </html>
    )
}
const testObj = {
    Description: { S: '' },
    Email: { S: 'daniyalyk@yahoo.com' },
    'Purchase Receipt': { S: '' },
    Vouchers: { SS: [''] },
    Time: { N: '1720307378' },
    City: { S: 'Damam' },
    Contact: { S: '+971049434869' },
    Name: { S: 'Daniyal Yahya' },
    isPurchaseReceipt: { S: 'false' },
    'User Language': { S: 'English' },
    IsValidPurchaseReceipt: { BOOL: false },
    Country: { S: 'KSA' },
    Prizes: { SS: ['D'] },
    UUID: { S: '6d5b54c3-2f0e-4e0b-94fc-bcb2dd667aca' }
}



