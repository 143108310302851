import { Box, Grid, Typography } from "@mui/material"
import { paths } from "@utils";
import { useNavigate } from "react-router-dom";

export const Footer = () => {
    const navigate = useNavigate();
    const handleOpenTerms = () => {
        navigate(paths.terms);
    }
    return (
        <Box display={'flex'} flexDirection={'row'} width={'100%'} sx={{ background: '#314FA2' }} justifyContent={'space-between'} padding={'1%'}>
            <Grid container>
                <Grid item xs={12} sm={6} textAlign={{ xs: 'center', sm: 'start' }}>
                    <Typography component={"span"} fontSize={{ xs: '1rem' }} color={'white'} fontWeight={700}>Copyright © RANI | All rights reserved</Typography>
                </Grid>
                <Grid item xs={12} sm={6} textAlign={{ xs: 'center', sm: 'end' }} >
                    <Typography onClick={handleOpenTerms} sx={{ cursor: 'pointer' }} component={"span"} fontSize={{ xs: '1rem' }} color={'white'} fontWeight={700}>Terms & Privacy Policy</Typography>
                </Grid>
            </Grid>
        </Box>
    )
}