import { baseUrl, prizeNames } from "@utils";

//GET - requests
export const getTemplate = async (queryParamA: string) => {
    try {
        const queryParams = new URLSearchParams({
            // queryParamA: queryParamA
        });
        const endpoint = `${baseUrl.aws}?${queryParams.toString()}`;
        const response: any = await fetch(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (!response.ok)
            throw new Error(`HTTP error! status: ${response.status}`);


        return response;
    } catch (e) {
        console.log(e);
        throw e;
    }
}
export const getUserIP = async () => {
    const response = await fetch("https://pro.ip-api.com/json?key=kX28opq8sKiZ2WV")
    if (!response.ok)
        throw new Error(`HTTP error! status: ${response.status}`);
    return response;
};

export const get_userExists = async (params: UserExists) => {
    const { email, isPurchaseReceipt } = params;
    try {
        const queryParams = new URLSearchParams({
            email: email,
            isPurchaseReceipt: isPurchaseReceipt
        });
        const endpoint = `${baseUrl.aws.userExists}?${queryParams.toString()}`;
        const response: any = await fetch(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (!response.ok)
            throw new Error(`HTTP error! status: ${response.status}`);

        return response;
    } catch (e) {
        console.log(e);
        throw e;
    }
}
export const get_presignedUrl = async () => {
    try {
        const endpoint = `${baseUrl.aws.presignedUrl}`;
        const response: any = await fetch(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (!response.ok)
            throw new Error(`HTTP error! status: ${response.status}`);

        // 
        return response;
    } catch (e) {
        console.log(e);
        throw e;
    }
}

export const get_prize = async (region: Region, isPurchaseReceipt: Boolean) => {
    try {
        const queryParams = new URLSearchParams({
            region: region,
            isPurchaseReceipt: isPurchaseReceipt.toString()
        });
        const endpoint = `${baseUrl.aws.prize}?${queryParams.toString()}`;
        const response: any = await fetch(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (!response.ok)
            throw new Error(`HTTP error! status: ${response.status}`);

        return response;
    } catch (e) {
        console.log(e);
        throw e;
    }
}
//POST - requests
export const postTemplate = async (body: {}) => {
    try {
        const endpoint = `${baseUrl.aws}/`;
        const response: any = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        });
        if (!response.ok)
            throw new Error(`HTTP error! status: ${response.status}`);


        return response;
    } catch (e) {
        console.log(e);
        throw e;
    }
}

export const post_addUser = async (body: UserPayload) => {
    try {
        const endpoint = `${baseUrl.aws.addUser}`;
        const response: any = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        });
        if (!response.ok)
            throw new Error(`HTTP error! status: ${response.status}`);


        return response;
    } catch (e) {
        console.log(e);
        throw e;
    }
}

//PUT - requests

export const put_purchaseReceipt = async (file: PurchaseReceipt, presignedURL: string) => {
    try {
        const endpoint = presignedURL;
        const response: any = await fetch(endpoint, {
            method: 'PUT',
            headers: {
                'Content-Type': file!.type,
            },
            body: file
        });
        if (!response.ok)
            throw new Error(`HTTP error! status: ${response.status}`);
        return response;
    } catch (e) {
        console.log(e);
        throw e;
    }
}
