import { Box } from "@mui/material";
import { observer } from "mobx-react";
import Confetti from "react-confetti";
export const ConfettiAnimation = observer(() => {
    const options = {}
    return (
        <Box position={'absolute'} zIndex={10} height={"100%"} width={'100%'} display={'flex'} overflow={'hidden'} sx={{ background: 'rgba(0,0,0,0.85)' }}>
            <Confetti
                colors={['#fee165']}
                width={window.innerWidth}
                height={window.innerHeight + window.outerHeight}
                gravity={0.1}
            />
        </Box>
    );
});