import React, { useContext } from "react";
import { countryCodeInitials, countrySelection, paths, presetCountry, useAppContext } from "@utils";
import { Avatar, Box, Grid, IconButton, Typography } from "@mui/material";
import CS_bg1 from '@images/CS_bg1&2.png';
import CS_WinRani from '@images/CS_WinRani.png';
import CS_WinRani_arabic from '@images/CS_WinRani_arabic.png';

import CS_RaniJuice from '@images/CS_RaniJuice.png';
import UAE_CS_RaniJuice from '@images/UAE_CS_RaniJuice.png'

import Chuncks_pack_l from '@images/Chuncks_pack_l.png';
import Chuncks_pack_r from '@images/Chuncks_pack_r.png';
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";


export const CountrySelection = observer(() => {
    const navigate = useNavigate();

    const {
        appStore: {
            getStore: appStore, page, country, language, client, userCountry
        }
    } = useAppContext();
    const isArabic = language === "Arabic";
    // const userCountry: string = localStorage?.getItem("countryCode") || "";
    const isUae = userCountry === "AE" || false;

    const matchUserCountry = (selectedCountry: string) => {
        if (userCountry === "PK")
            return true;
        if (userCountry && selectedCountry.includes(userCountry))
            return true;
        return false;
    }
    const getKeyByValue = (object: any, value: any) => {
        return Object.keys(object).find(key => object[key] === value);
    }
    const handleCountryOnClick = (selectedCountry: string) => {
        // if (matchUserCountry(selectedCountry)) {
        //@ts-ignore
        appStore.setCountry = (getKeyByValue(countryCodeInitials, selectedCountry));
        appStore.setPage = ("Home");
        // }
        // else
        //     navigate(paths.error);
    }
    const isCountryHidden = (item: any): boolean => {
        if (
            item.name[language] === countrySelection.UAE.name[language]
            || item.name[language] === countrySelection.Qatar.name[language]
            || item.name[language] === countrySelection.Oman.name[language]
            || item.name[language] === countrySelection.KSA.name[language]
            || item.name[language] === countrySelection.Jordan.name[language]
            || item.name[language] === countrySelection.Kuwait.name[language]
            || item.name[language] === countrySelection.Iraq.name[language]
        )
            return true;
        return false;
    }
    return (
        <Grid container className="min-h-screen ">
            <Grid item xs={12} minHeight={{ xs: '50vh', sm: '50vh' }} className="bg-cover" sx={{ backgroundImage: `url(${CS_bg1})`, backgroundPosition: 'bottom', alignContent: 'center' }}>
                <Grid container sx={{ mt: 5 }}>
                    <Grid item xs={12} sm={6} display={'flex'} justifyContent={'center'} position={'relative'} >
                        <Avatar variant="square" src={isArabic ? CS_WinRani_arabic : CS_WinRani} sx={{ height: 'auto', width: { xs: '50%', sm: '60%' }, zIndex: 2 }} />
                        <Avatar className="breath-left" variant="square" src={Chuncks_pack_l} sx={{ height: 'auto', width: { xs: '30%', sm: '40%' }, rotate: { xs: '-20deg', sm: '-10deg' }, position: 'absolute', top: { xs: '-5%', sm: 0 }, left: { xs: '10%', sm: '5%' }, zIndex: 1 }} />
                        <Avatar className="breath-right" variant="square" src={Chuncks_pack_r} sx={{ height: 'auto', width: { xs: '30%', sm: '40%' }, rotate: { xs: '-25deg', sm: '-25deg' }, position: 'absolute', top: { xs: '-5%', sm: 0 }, right: { xs: '10%', sm: '0' }, zIndex: 1 }} />

                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Avatar variant="square" src={isUae ? UAE_CS_RaniJuice : CS_RaniJuice} sx={{ height: 'auto', width: '100%' }} />
                    </Grid>
                </Grid>
            </Grid>
            {/* <Grid item xs={12} minHeight={'10vh'} >
                <Grid container spacing={4}>
                    <Grid item xs={12} >
                        <Typography width={'100%'} component={"span"} fontSize={{ xs: '1.5rem', sm: '3rem' }} color={'white'} fontWeight={700}>{isArabic ? "الرجاء تحديد بلدك" : "Please select your country"}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container sx={{ justifyContent: 'center' }}>
                            {Object.values(countrySelection).map((item: any, index: any) => {
                                if (isCountryHidden(item)) return;
                                return (
                                    <Grid key={index} item xs={12} sm={3} md={2.4} lg={1.7}>
                                        <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                                            <IconButton className="hover:opacity-90" sx={{ display: "flex", flexDirection: 'column' }} disableRipple disableTouchRipple disableFocusRipple onClick={() => handleCountryOnClick(countryCodeInitials[Object.keys(countrySelection)[index] as Country])}>
                                                <Avatar variant="square" src={item.flag} sx={{ height: '100%', width: '160px' }} />
                                                <Typography sx={{ visibility: { xs: "visible", sm: 'visible' } }} width={'100%'} component={"span"} fontSize={'1.5rem'} color={'white'} fontWeight={700}>{item.name[language]}
                                                </Typography>
                                            </IconButton>
                                        </Box>
                                    </Grid>
                                )
                            }, {})
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid> */}
        </Grid>
    )
})