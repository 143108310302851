import { Box, Typography } from "@mui/material"
import { FormContentArabic, FormContentEnglish } from "./_"
import { useAppContext } from "@utils";
import { useEffect, useState } from "react";
import { observer } from 'mobx-react';

export const FormContent = observer(() => {
    const {
        appStore: {
            getStore: appStore, country, language, postAddUser
        }
    } = useAppContext();
    const isArabic = language === "Arabic";

    const animate = () => {
        appStore.setCanAnimationClass = 'shake';
        setTimeout(() => {
            appStore.setCanAnimationClass = "abc";
        }, 2000)
        setTimeout(() => {
            appStore.setTicketAnimationClass = 'ticket';
            appStore.setShowConfetti = true;
        }, 3000)
        setTimeout(() => {
            appStore.setCancelConfetti = true
        }, 9000)
    }
    const getCity = (values: any) => {
        return values.city === "Other" ? values.other : values.city
    }
    const onSubmit = async (values: any) => {
        try {
            const payload: User = {
                name: `${values.firstName} ${values.lastName}`,
                city: getCity(values),
                email: values.email,
                phoneNumber: `${values.countryCode}${values.phoneNumber}`,
                purchase_receipt: values.purchase_receipt,
                country: country
            }
            //@ts-ignore
            const gaDataLayer = dataLayer ?? undefined;
            if (typeof gaDataLayer !== 'undefined') {
                gaDataLayer.push({
                    'event': 'rani_form_submission',
                    'event_category': 'Form Submissions',
                    'event_action': 'Submitted',
                    'event_label': 'Rani_Form',
                    'event_value': JSON.stringify(payload),
                });
            }
            const resp = await postAddUser(payload);

        }
        catch (e) {
            console.log(e)
        }
    }
    return (
        <Box display={'flex'} flexDirection={'column'} width={'100%'} height={'100%'} maxWidth={'900px'} zIndex={2} padding={'0 1%'}>
            <Box borderRadius={'10px 10px 0 0'} display={'flex'} height={'auto'} padding={'2% 1%'} justifyContent={'center'} alignItems={'center'} sx={{ background: "linear-gradient(180deg, #FD8484 0%,  #F06661 77%, #DF3F34 100%)" }}>
                <Typography width={'100%'} component={"span"} fontSize={{ xs: '1.25rem', sm: '3rem' }} color={'white'} fontWeight={700}>
                    {isArabic ? "!!أدخل تفاصيلك واربح" : "ENTER YOUR DETAILS AND WIN!! "}
                </Typography>
            </Box>
            <Box display={'flex'} flexDirection={'column'} justifyContent={'top'} alignItems={'center'} width={'100%'} height={'100%'} padding={{ xs: '5% 1%', sm: '5%' }} sx={{ background: 'white', borderRadius: '0 0 10px 10px' }}>
                {isArabic ?
                    <FormContentArabic animate={animate} onSubmit={onSubmit} />
                    :
                    <FormContentEnglish animate={animate} onSubmit={onSubmit} />
                }
            </Box>
        </Box >
    )
})