import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { AppHeader, ComingSoon, Footer, LanguageButton } from '@components';
import { navigation, useAppContext } from '@utils';
import { ConfettiAnimation } from '@animations';
import { observer } from 'mobx-react';
import { MaintainencePage } from './MaintainencePage';

export const HomePage = observer(() => {
    const {
        appStore: {
            getStore: appStore, page, showConfetti, getUserIP: get_user_ip, userCountry
        }
    } = useAppContext();
    const getUserIP = async () => {
        await get_user_ip();
    }
    useLayoutEffect(() => {
        getUserIP()
        // appStore.setPage = ("Error");
    }, [])
    return (
        <>
            <LanguageButton />
            {showConfetti && <ConfettiAnimation />}
            {React.createElement(navigation[page])}
            {/* <ComingSoon /> */}
            {/* {localStorage.getItem('test') === 'enabled' ?
                React.createElement(navigation[page])
                : <MaintainencePage />} */}
        </>
    );
})

