import { ErrorPage, HomePage, TermsAndConditionsPage } from "@pages";
import uae_flag_mini from '@images/uae_flag_mini.png';
import sa_flag_mini from '@images/sa_flag_mini.png';
import iraq_flag_mini from '@images/iraq_flag_mini.png';
import jordan_flag_mini from '@images/jordan_flag_mini.png';
import qatar_flag_mini from '@images/qatar-flag-mini.png';
import oman_flag_mini from '@images/oman-flag-mini.png';
import kuwait_flag_mini from '@images/kuwait-flag-mini.png';
import sa_flag from '@images/sa_flag.png';
import uae_flag from '@images/uae_flag.png';
import iraq_flag from '@images/iraq_flag.png';
import jordan_flag from '@images/jordan_flag.png';
import qatar_flag from '@images/qatar-flag.png';
import oman_flag from '@images/oman-flag.png';
import kuwait_flag from '@images/kuwait-flag.png';
import no_flag from '@images/no-flag.png';
import * as yup from "yup";
import { CountrySelection, Home } from "@components";


export const navigation = { "Home": Home, "CountrySelection": CountrySelection }
export const baseUrl = {
    aws: {
        addUser: "https://ipu2ln88fc.execute-api.us-east-2.amazonaws.com/Dev/user",
        userExists: "https://ipu2ln88fc.execute-api.us-east-2.amazonaws.com/Dev/user",
        presignedUrl: "https://ipu2ln88fc.execute-api.us-east-2.amazonaws.com/Dev/user/presigned-url",
        prize: "https://ipu2ln88fc.execute-api.us-east-2.amazonaws.com/Dev/user/prizes",
    },
    ecopon: "https://www.e-copon.com/uatapi/api/value/JsonRequest",
    userIP: 'https://api.ipify.org/?format=json',
};


export const paths: { [key in AllowedPaths]: string } = {

    home: '/',
    error: '/error',
    terms: '/Terms-and-Conditions'
};

export const routes: IRoutes = {
    public: [
        { path: paths.error, component: ErrorPage },
        { path: paths.terms, component: TermsAndConditionsPage },
    ],
    auth: [

    ],
    unAuth: [
        { path: paths.home, component: HomePage },
    ],
};

export const validationSchema = yup.object({
    firstName: yup.string().required("Full Name is required"),
    lastName: yup.string().required("Full Name is required"),
    city: yup.string().required("City is required"),
    other: yup.string().required("City is required"),
    email: yup.string().email("Enter a valid email").required("Email is required"),
    countryCode: yup.string().required("Country Code is required"),
    phoneNumber: yup.number().required("Phone Number is required"),
    purchase_receipt: yup.mixed().required("Purchase Receipt is required"),
    t_c: yup.bool().oneOf([true], "Accept Terms & Conditions is required"),
});

export const cities: Cities = {
    "KSA": {
        "English": ["Riyadh", "Jeddah", "Damam", "Mecca", "Madina", "Taif", "Tabuk", "Al Mubarraz", "Other"],
        "Arabic": ["الرياض", "جدة", "الدمام", "مكة", "المدينة", "الطائف", "تبوك", "المبرز", "أخرى"]
    },
    "UAE": {
        "English": [
            "Dubai",
            "Sharjah",
            "Ajman",
            "Abu Dhabi",
            "Al Ain",
            "Fujairah",
            "Ras Al Khaimah",
            "Umm Al Quwain",
        ],
        "Arabic": [
            "دبي",
            "الشارقة",
            "عجمان",
            "أبو ظبي",
            "العين",
            "الفجيرة",
            "رأس الخيمة",
            "أم القيوين",
        ]
    },
    "Iraq": {
        "English": ["Baghdad", "Erbil", "Basra", "Mosul", "Other"],
        "Arabic": ["بغداد", "أربيل", "البصرة", "الموصل", "أخرى"]
    },
    "Jordan": {
        "English": ["Amman", "Zarqa", "Irbid", "Russeifa", "Other"],
        "Arabic": ["عمان", "الزرقاء", "إربد", "الرصيفة", "أخرى"]
    },
    "Qatar": {
        "English": ["Doha", "Al Rayyan", "Al Wakrah", "Al Khor", "Dukhan", "Other"],
        "Arabic": ["الدوحة", "الريان", "الوكرة", "الخور", "دخان", "أخرى"]
    },
    "Oman": {
        "English": ["Muscat", "Salalah", "Sohar", "Nizwa", "Sur", "Other"],
        "Arabic": ["مسقط", "صلالة", "صحار", "نزوى", "صور", "أخرى"]
    },
    "Kuwait": {
        "English": ["Kuwait City", "Al Ahmadi", "Hawalli", "Salmiya", "Jahra", "Other"],
        "Arabic": ["مدينة الكويت", "الأحمدي", "حولي", "السالمية", "الجهراء", "أخرى"]
    }

}

export const storeDefaultValues: StoreDefaultValuesProps = {
    page: 'CountrySelection',
    country: 'KSA',
    language: 'Arabic',
    canAnimationClass: null,
    ticketAnimationClass: null,
    setPage: (newPage: Page) => { },
    setCountry: (newCountry: Country) => { },
    setLanguage: (newLanguage: Language) => { },
    setCanAnimationClass: (newClass: string | null) => { },
    setTicketAnimationClass: (newClass: string | null) => { },
    showConfetti: false,
    setShowConfetti: (newvalue: boolean) => { },
    cancelConfetti: false,
    setShowCancelConfetti: (newvalue: boolean) => { },

}

export const prizeNames: PrizeNames = {
    "UAE": {
        "A": "Noon Discount codes",
        "B": "Digital Wallpaper"
    },
    "KSA": {
        "C": "Nana Discount codes",
        "D": "Digital Wallpaper"
    },
    "Riyadh": {
        "E": "Noon Discount codes",
        "F": "Digital Wallpaper"
    },
    "Jordan": {
        "G": "Talabat discount codes",
        "H": "Digital Wallpaper"
    },
    "Iraq": {
        "I": "Digital Wallpaper"
    },
    "Qatar": {
        "J": "Digital Wallpaper"
    },
    "Oman": {
        "K": "Digital Wallpaper"
    },
    "Kuwait": {
        "L": "Digital Wallpaper"
    }
};

export const presetCountry: PresetCountry = {
    "KSA": {
        cc: "+966",
        flag: sa_flag_mini
    },
    "UAE": {
        cc: "+971",
        flag: uae_flag_mini
    },
    "Iraq": {

        cc: "+964",
        flag: iraq_flag_mini
    },
    "Jordan": {

        cc: "+962",
        flag: jordan_flag_mini
    },
    "Qatar": {
        cc: "+974",
        flag: qatar_flag_mini
    },
    "Oman": {

        cc: "+968",
        flag: oman_flag_mini
    },
    "Kuwait": {

        cc: "+965",
        flag: kuwait_flag_mini
    },


}
export const countrySelection: CountrySelection = {
    "KSA": {
        name: {
            "English": "Saudi Arabia",
            "Arabic": "السعودية",
        },
        cc: "+966",
        flag: sa_flag
    },
    "UAE": {
        name: {
            "English": "United Arab Emirates",
            "Arabic": "الإمارات العربية المتحدة"
        },
        cc: "+971",
        flag: uae_flag
    },
    "Iraq": {
        name: {
            "English": "Iraq",
            "Arabic": "العراق",
        },
        cc: "+964",
        flag: iraq_flag
    },
    "Jordan": {
        name: {
            "English": "Jordan",
            "Arabic": "الأردن",
        },
        cc: "+962",
        flag: jordan_flag
    },
    "Qatar": {
        name: {
            "English": "Qatar",
            "Arabic": "ﻗَﻄَﺮ",
        },
        cc: "+974",
        flag: qatar_flag
    },
    "Oman": {
        name: {
            "English": "Oman",
            "Arabic": "عمان",
        },
        cc: "+968",
        flag: oman_flag
    },
    "Kuwait": {
        name: {
            "English": "Kuwait",
            "Arabic": "كويت",
        },
        cc: "+965",
        flag: kuwait_flag
    },


}
export const countryCodeInitials = {
    "UAE": "AE",
    "KSA": "KSA",
    "Iraq": "IQ",
    "Jordan": "JO",
    "Qatar": "QA",
    "Oman": "OM",
    "Kuwait": "KW"
}
export const phoneNumberLengths = {
    "UAE": 9,
    "KSA": 9,
    "Jordan": 9,
    "Iraq": 10,
    "Qatar": 8,
    "Oman": 8,
    "Kuwait": 8
};

