import { flow, toJS, makeAutoObservable, set } from 'mobx';
import { DEFAULT_HTTP_RESPONSE } from './defaults';
import { countryCodeInitials, storeDefaultValues as defaultValues, getTemplate, getUserIP, get_presignedUrl, get_prize, get_userExists, postTemplate, post_addUser, prizeNames, put_purchaseReceipt } from '@utils';
import { toast } from 'react-toastify';

export class AppStore {
    sessionResponse: HttpResponse = DEFAULT_HTTP_RESPONSE;
    sessionSecondaryResponse: HttpResponse = DEFAULT_HTTP_RESPONSE;

    page: Page = defaultValues.page
    country: Country = defaultValues.country;
    language: Language = defaultValues.language;
    canAnimationClass: string | null = null;
    ticketAnimationClass: string | null = null;
    showConfetti: boolean = defaultValues.showConfetti;
    cancelConfetti: boolean = defaultValues.cancelConfetti;
    chunkExplosionClass: string | null = null;
    client: any = null;
    userCountry: string | null = ''
    presignedUrl: string = "";
    prize: string = "";
    isEmailRequired: boolean = true;
    constructor() {
        makeAutoObservable(this)
    }

    get getStore() {
        return this;
    }

    set setPage(newValue: Page) {
        this.page = newValue;
    }
    set setCountry(newValue: Country) {
        this.country = newValue;
    }
    set setLanguage(newValue: Language) {
        this.language = newValue;
    }
    set setCanAnimationClass(newValue: string | null) {
        this.canAnimationClass = newValue;
    }
    set setTicketAnimationClass(newValue: string | null) {
        this.ticketAnimationClass = newValue;
    }
    set setShowConfetti(newValue: boolean) {
        this.showConfetti = newValue;
    }
    set setCancelConfetti(newValue: boolean) {
        this.cancelConfetti = newValue;
    }

    resetSession = () => {
        this.canAnimationClass = null;
        this.ticketAnimationClass = null;
        this.showConfetti = false;
        this.cancelConfetti = false;
        this.chunkExplosionClass = null;
        this.page = "CountrySelection";
        this.sessionResponse = DEFAULT_HTTP_RESPONSE;
    }

    getRegion = (city: string): Region => {
        if (this.country === "KSA" && city === "Riyadh")
            return "Riyadh";
        return this.country;
    }
    getOptionalEmailRegion = (cc: string | null): boolean => {
        return cc === "PK"
            || cc === countryCodeInitials.UAE
            || cc === countryCodeInitials.Iraq
            || cc === countryCodeInitials.Jordan
            || cc === countryCodeInitials.Qatar
            || cc === countryCodeInitials.Oman
            || cc === countryCodeInitials.Kuwait
            || cc === countryCodeInitials.KSA
    }
    getUserIP = flow(function* (
        this: AppStore,
    ): any {
        try {
            this.sessionSecondaryResponse.status = 'pending';
            // const response = yield getUserIP();
            // const userResponse = yield response.json();
            // this.client = userResponse;
            // const userCC = userResponse.countryCode

            const userCC = localStorage.getItem('countryCode');
            if (userCC === "PK" || userCC === "AE" || userCC === 'QA') {
                this.language = 'English';
            }
            this.isEmailRequired = !this.getOptionalEmailRegion(userCC)
            this.userCountry = userCC;
            this.sessionSecondaryResponse.status = 'ok';
        } catch (err: any) {
            console.log(err);
            this.sessionSecondaryResponse.status = 'badRequest';
            this.sessionSecondaryResponse.errMessage = 'Bad Request';
            return err;
        }
    }).bind(this);

    getUserExists = flow(function* (
        this: AppStore,
        params: UserExists
    ): any {
        try {
            this.sessionSecondaryResponse.status = 'pending';
            const response = yield get_userExists(params);
            const userResponse = yield response.json();
            this.sessionSecondaryResponse.status = 'ok';
            return userResponse;
        } catch (err: any) {
            console.log(err);
            this.sessionSecondaryResponse.status = 'badRequest';
            this.sessionSecondaryResponse.errMessage = 'Bad Request';
            return err;
        }
    }).bind(this);


    getPresignedUrl = flow(function* (
        this: AppStore,
    ): any {
        try {
            this.sessionSecondaryResponse.status = 'pending';
            const response = yield get_presignedUrl();
            const userResponse = yield response.json();
            this.presignedUrl = userResponse.body.presignedUrl;

            this.sessionSecondaryResponse.status = 'ok';
        } catch (err: any) {
            this.sessionSecondaryResponse.status = 'badRequest';
            this.sessionSecondaryResponse.errMessage = 'Bad Request';
        }
    }).bind(this);

    getPrize = flow(function* (
        this: AppStore,
        region: Region,
        isPurchaseReceipt: Boolean
    ): any {
        try {
            this.sessionSecondaryResponse.status = 'pending';
            const response = yield get_prize(region, isPurchaseReceipt);
            const userResponse = yield response.json();
            this.sessionSecondaryResponse.status = 'ok';
            return userResponse;
        } catch (err: any) {
            this.sessionSecondaryResponse.status = 'badRequest';
            this.sessionSecondaryResponse.errMessage = 'Bad Request';
        }
    }).bind(this);

    putPurchaseReceipt = flow(function* (
        this: AppStore,
        file: PurchaseReceipt,
    ): any {
        try {
            this.sessionSecondaryResponse.status = 'pending';
            const response = yield put_purchaseReceipt(file, this.presignedUrl);
            const userResponse = yield response.json();
            this.sessionSecondaryResponse.status = 'ok';
        } catch (err: any) {
            this.sessionSecondaryResponse.status = 'badRequest';
            this.sessionSecondaryResponse.errMessage = 'Bad Request';
        }
    }).bind(this);

    postAddUser = flow(function* (
        this: AppStore,
        user: User
    ): any {
        try {
            this.sessionResponse.status = 'pending'
            let userPayload: UserPayload;
            const userExists = false;
            if (!userExists) {
                const region: Region = yield this.getRegion(user.city);
                let prizes, vouchers;
                if (user.purchase_receipt.type) {
                    const resp_getPresignedUrl = yield this.getPresignedUrl();
                    const resp_putPurchaseReceipt = yield this.putPurchaseReceipt(user.purchase_receipt);
                    if (user.email.length > 0) {
                        const resp_getPrize = yield this.getPrize(region, true);
                        ({ prizes, vouchers } = (JSON.parse(resp_getPrize.body)).data);
                    }
                    else {
                        prizes = null;
                        vouchers = null;
                    }
                    userPayload = { ...user, purchase_receipt: this.presignedUrl.split("?")[0], ...(prizes && { prizes }), ...(vouchers && { vouchers }), userLanguage: this.language, description: '' };
                }
                else {
                    if (user.email.length > 0) {
                        const resp_getPrize = yield this.getPrize(region, false);
                        ({ prizes, vouchers } = (JSON.parse(resp_getPrize.body)).data);
                    }
                    else {
                        prizes = null;
                        vouchers = null;
                    }
                    userPayload = { ...user, purchase_receipt: "", ...(prizes && { prizes }), ...(vouchers && { vouchers }), userLanguage: this.language, description: '' }
                }
                const response = yield post_addUser(userPayload);
                const userResponse = yield response.json();
                if (userResponse.statusCode === 200) {
                    this.sessionResponse.status = 'ok';
                    this.canAnimationClass = 'shake';
                    setTimeout(() => {
                        this.chunkExplosionClass = 'chunk_explosion';
                    }, 1000);
                    setTimeout(() => {
                        this.canAnimationClass = 'stop-shake';
                    }, 2000);
                    setTimeout(() => {
                        this.ticketAnimationClass = 'ticket';
                        this.showConfetti = true;
                    }, 4000);
                    setTimeout(() => {
                        this.cancelConfetti = true
                    }, 9000);
                }
                else {
                    this.sessionResponse.status = userResponse.status;
                    this.sessionResponse.errMessage = userResponse.body.message
                    toast(userResponse.body.message, { type: 'error' });

                }
            }
            else {
                this.sessionResponse.status = '404';
                this.sessionResponse.errMessage = 'Bad request'
                toast('Bad Request', { type: 'error' });
            }

        } catch (err: any) {
            console.log(err);
            this.sessionResponse.status = 'badRequest';
            this.sessionResponse.errMessage = 'Bad Request';
            toast('Bad Request', { type: 'error' });

        }
    }).bind(this);


}

